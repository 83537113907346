import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';
import classes from './TextInput.module.css';

export const TextInput = (props: TextInputProps) => (
  <MantineTextInput
    {...props}
    classNames={{
      input: classes.input,
      label: classes.label,
    }}
  />
);
