'use client';

import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

interface Props {
  data: number[];
}

export const UserTraffic = ({ data = [] }: Props) => {
  const [maxValue, setMaxvalue] = useState(0)

  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      chart: {
        height: '100%',
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#8CB80A'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        lineCap: 'butt',
        width: 2,
      },
      title: {
        text: 'Lietotāju skaits',
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 6,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      xaxis: {
        type: 'datetime',
        categories: Array.from({ length: new Date().getDate() }, (_, index) => {
          const date = new Date();
          date.setDate(1);
          date.setMonth(date.getMonth());
          date.setDate(date.getDate() + index);
          return date.getTime();
        }),
        labels: {
          datetimeUTC: false,
          format: 'dd.MM.yyyy',
        },
        title: {
          text: 'Datums',
        },
      },
      
      yaxis: {
        title: {
          text: 'Skaits',
        },
        min: 0,
        max:  Math.max(...data) + 1,
        forceNiceScale: true
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    }),
    [],
  );

  const series = useMemo(
    () => [
      {
        name: 'Lietotāji pa dienām',
        data,
      },
    ],
    [data],
  );

  useEffect(() => {
    const maxValue = Math.max(...data);
    setMaxvalue(maxValue)
  }, [data])

  return (
    <div style={{ height: 300 }}>
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
        height={300}
      />
    </div>
  );
};
