'use client';

import { Group, Box, Button, FileButton } from '@mantine/core';
import { Camera } from '@phosphor-icons/react/dist/ssr';
import Image from 'next/image';
import { useState } from 'react';
import classes from './ProfileImage.module.css';

interface Props {
  onChange: (file: File | undefined) => void;
}

export const ProfileImage = ({ onChange }: Props) => {
  const [file, setFile] = useState<File>();

  const getImageDetails = () => {
    let url = '';

    if (file) {
      url = URL.createObjectURL(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        url = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }

    return url;
  };

  const handleImageAdd = (newFile: File | null) => {
    if (newFile) {
      onChange(newFile);
      setFile(newFile);
    }
  };

  const handleRemoveFile = () => {
    onChange(undefined);
    setFile(undefined);
  };

  return (
    <Group>
      <FileButton
        onChange={handleImageAdd}
        accept="image/png,image/jpeg,image/jpg"
      >
        {(props) => (
          <Box className={classes.imageBox} {...props} h={128} w={128}>
            {file ? (
              <Image
                src={getImageDetails()}
                height={128}
                width={128}
                alt="Profile image"
              />
            ) : (
              <Camera height={28} width={28} color="#D4D6DD" />
            )}
          </Box>
        )}
      </FileButton>
      <Button
        variant="transparent"
        c="gray.5"
        fw={400}
        onClick={handleRemoveFile}
      >
        Dzēst attēlu
      </Button>
    </Group>
  );
};
