'use client';

import { useEffect, useState } from 'react';
import {
  ActionIcon,
  Box,
  Checkbox,
  Flex,
  LoadingOverlay,
  Table as MantineTable,
  MultiSelect,
  Text,
  UnstyledButton,
  keys,
  rem,
} from '@mantine/core';
import {
  CaretLeft,
  CaretRight,
  FunnelSimple,
  MagnifyingGlass,
} from '@phosphor-icons/react/dist/ssr';
import cx from 'classnames';
import { TextInput } from '../TextInput';
import classes from './Table.module.css';
import { RowActions } from './RowActions';
import { RowAction, TableDataRow, TableHeader } from './interfaces';
import { Link } from '../Link';

interface Filters {
  [key: string]: Array<string | number>;
}

interface Props {
  headers: TableHeader[];
  data: TableDataRow[];
  searchable?: boolean;
  selectable?: boolean;
  filters?: string[];
  loading?: boolean;
  allActionsInMenu?: boolean;
  rowActions?: RowAction[];
  onSelect?: (ids: string[]) => void;
}

const PAGE_SIZE = 30 as const;

export const Table = ({
  data,
  headers,
  filters,
  searchable,
  selectable,
  allActionsInMenu,
  loading,
  rowActions,
  onSelect,
}: Props) => {
  const [tableData, setTableData] = useState(data);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState<string[]>([]);
  const [currentFilters, setCurrentFilters] = useState<Filters>({});

  const getStartEnd = () => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex =
      startIndex + PAGE_SIZE <= data.length
        ? startIndex + PAGE_SIZE
        : data.length;

    return { start: startIndex, end: endIndex };
  };

  const getStepData = (start: number, end: number) => {
    const slice = [...data].splice(start, end);
    setTableData(slice);
  };

  const setInitialState = (initialData: TableDataRow[]) => {
    setTableData(initialData);
    const { start, end } = getStartEnd();
    const slice = [...initialData].splice(start, end);

    setTableData(slice);
  };

  useEffect(() => {
    if (data) {
      setInitialState(data);
    }
  }, [data]);

  const filterData = (dataToFilter: TableDataRow[], search: string) => {
    const searchQuery = search.toLowerCase().trim();
    return dataToFilter.filter((item) =>
      keys(dataToFilter[0]).some((key) =>
        item[key].value?.toLowerCase().includes(searchQuery),
      ),
    );
  };

  const getFilters = (key: string) => {
    const values = new Set<string>();
    data.forEach((row) => {
      const column = row[key];
      if (column.value) {
        values.add(column.value);
      }
    });

    return [...values];
  };

  const filterDataUsingFilters = (newFilters: Filters) => {
    if (keys(newFilters).length === 0) setTableData(data);

    setCurrentFilters(newFilters);

    const filtered = data.filter((item) => {
      const filteredColumns = keys(newFilters);

      const satisfiesAllFilters = filteredColumns.every((column) =>
        newFilters[column].some(
          (filterValue) => filterValue === item[column].value.toLowerCase(),
        ),
      );

      return satisfiesAllFilters;
    });

    setTableData(filtered);
  };

  const handleFilterChange = (values: string[], columnKey: string) => {
    if (values.length === 0) {
      const newFilters = { ...currentFilters };
      delete newFilters[columnKey];
      setCurrentFilters(newFilters);
      filterDataUsingFilters(newFilters);
    } else {
      const normalizedValues = values.map((v) => v.toLowerCase());
      const newFilters: Filters = {
        ...currentFilters,
        [columnKey]: normalizedValues,
      };
      filterDataUsingFilters(newFilters);
    }
  };

  const handleSearch = (event: { target: { value: string } }) => {
    const { value } = event.target;
    setQuery(value);
    setTableData(filterData(data, value));
  };

  const getTotalPages = () => Math.ceil((data || []).length / PAGE_SIZE);

  const handleStep = (nextPage: number) => {
    const totalPages = getTotalPages();

    if (nextPage >= 1 && nextPage <= totalPages) {
      setCurrentPage(nextPage);
      const start = (nextPage - 1) * PAGE_SIZE;
      const end =
        start + PAGE_SIZE <= data.length ? start + PAGE_SIZE : data.length;

      getStepData(start, end);
    }
  };

  const handleCheckToggle = () => {
    if (selected.length !== data.length) {
      const ids = data.map((row) => row._id.value);
      setSelected(ids);

      if (onSelect) onSelect(ids);
    } else {
      setSelected([]);
      if (onSelect) onSelect([]);
    }
  };

  const handleSelect = (id: string) => {
    if (selected.includes(id)) {
      const ids = selected.filter((item) => item !== id);
      setSelected(ids);
      if (onSelect) onSelect(ids);
    } else {
      const ids = [...selected, id];
      setSelected(ids);
      if (onSelect) onSelect(ids);
    }
  };

  return (
    <>
      <Flex gap="1rem" mb={rem(40)}>
        {searchable && (
          <TextInput
            leftSection={<MagnifyingGlass />}
            value={query}
            onChange={handleSearch}
          />
        )}
        {filters &&
          filters.map((key) => {
            const head = headers.find((h) => h.value === key);
            return (
              <MultiSelect
                key={key}
                placeholder={head?.label}
                data={getFilters(key)}
                rightSection={<FunnelSimple />}
                onChange={(values) => handleFilterChange(values, key)}
                maw={200}
              />
            );
          })}
      </Flex>
      <Box pos="relative">
        <LoadingOverlay visible={loading} />
        {selected.length > 0 && (
          <Box className={classes.selectedHeader}>
            <Flex gap={rem(10)} align="center">
              <Text c="#D4D6DD" fz={rem(12)}>
                {`${selected.length} izvēlēti`}
              </Text>
              <UnstyledButton>Redigēt</UnstyledButton>
            </Flex>
          </Box>
        )}
        <MantineTable withRowBorders={false}>
          <MantineTable.Thead>
            <MantineTable.Tr className={classes.divider}>
              {selectable && (
                <MantineTable.Th className={classes.head}>
                  <Checkbox
                    radius={3}
                    checked={data.length === selected.length}
                    indeterminate={
                      selected.length > 0 && selected.length !== data.length
                    }
                    onClick={handleCheckToggle}
                  />
                </MantineTable.Th>
              )}
              {headers.map((head) => (
                <MantineTable.Th key={head.value} className={classes.head}>
                  {head.label}
                </MantineTable.Th>
              ))}
              {rowActions && (
                <MantineTable.Th className={classes.head}>
                  Darbības
                </MantineTable.Th>
              )}
            </MantineTable.Tr>
          </MantineTable.Thead>
          <MantineTable.Tbody>
            {tableData.map((row) => (
              <MantineTable.Tr
                key={row._id.value}
                className={cx({
                  [classes.selected]: selected.includes(row._id.value),
                })}
              >
                {selectable && (
                  <MantineTable.Td>
                    <Checkbox
                      radius={3}
                      onClick={() => handleSelect(row._id.value)}
                      checked={selected.includes(row._id.value)}
                    />
                  </MantineTable.Td>
                )}
                {headers.map((column, index) => {
                  const cell = row[column.value];
                  const valueToRender = cell?.display ?? cell?.value;

                  return (
                    <MantineTable.Td key={`r${index}-${column}`}>
                      {cell?.link && (
                        <Link href={row[column.value].link || ''}>
                          {valueToRender}
                        </Link>
                      )}
                      {!cell?.link && valueToRender}
                    </MantineTable.Td>
                  );
                })}
                {rowActions && (
                  <MantineTable.Td>
                    <RowActions
                      allActionsInMenu={allActionsInMenu}
                      row={row}
                      actions={rowActions}
                    />
                  </MantineTable.Td>
                )}
              </MantineTable.Tr>
            ))}
          </MantineTable.Tbody>
        </MantineTable>

        <Flex justify="center" mt={rem(40)} gap={rem(20)} align="center">
          <Text c="#D4D6DD" fz={rem(12)}>
            {`${getStartEnd().start + 1} - ${getStartEnd().end} no ${
              data.length
            }`}
          </Text>
          <Flex>
            <ActionIcon
              h={rem(20)}
              variant="transparent"
              aria-label="Previous"
              onClick={() => handleStep(currentPage - 1)}
            >
              <CaretLeft />
            </ActionIcon>
            <ActionIcon
              h={rem(20)}
              variant="transparent"
              aria-label="Next"
              onClick={() => handleStep(currentPage + 1)}
            >
              <CaretRight />
            </ActionIcon>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
