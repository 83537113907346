import { Text } from '@mantine/core';
import { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

export const SectionTitle = ({ children, ...other }: Props) => (
  <Text fz={22} fw={400} c="#1F2024" {...other}>
    {children}
  </Text>
);
