import { Radio as MantineRadio, RadioProps } from '@mantine/core';
import { forwardRef } from 'react';
import classes from './Radio.module.css';

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => (
  <MantineRadio
    classNames={{
      root: props.checked ? classes.checked : '',
      body: classes.radio,
    }}
    ref={ref}
    {...props}
    data-checked={props.checked}
  />
));
