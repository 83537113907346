
import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { getAuthToken } from '@/app/[lang]/@unauthorized/actions';
import { deleteToken } from '@/app/[lang]/@authorized/logout/actions';

export const OK_STATUSES = [200, 201];

/**
 * Custom axios instance
 * @returns AxiosInstance
 */


export const httpClient = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: 'https://my.neiboo.com/neibooapi',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      console.log(error.response.status)
      if ( error.response && error.response.status === 401 ) {
        deleteToken();

      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use(
    async (config) => {
      const token = await getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};
