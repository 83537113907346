import { OK_STATUSES, httpClient } from '@/util/httpClient';

export const getItemImage = async (imageUrls: string[]) => {
  try {
    const resp = await httpClient().post<string[]>(
      '/inventory_mgmt/tools/document/get',
      { files: imageUrls },
    );

    if (OK_STATUSES.includes(resp.status)) {
      return resp.data;
    }
  } catch (error) {
    // errorNotification('Failed to get item images');
  }

  return undefined;
};
