import { ButtonProps, Button as MantineButton } from '@mantine/core';
import { ReactNode } from 'react';
import cx from 'classnames';
import classes from './Button.module.css';

interface Props extends ButtonProps {
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
  children: ReactNode;
  disabled?: boolean;
}
export const Button = ({
  onClick,
  children,
  type = 'button',
  className,
  disabled,
  ...rest
}: Props) => (
  <MantineButton
    {...rest}
    type={type}
    className={cx(classes.button, className)}
    onClick={onClick}
    disabled={disabled} 
  >
    {children}
  </MantineButton>
);
