'use client';

import React, { useMemo } from 'react';
import cx from 'classnames';
import classes from './SimpleStatBlock.module.scss';

interface Props {
  title: string;
  rows: { name: string; value: number | string }[];
  dark?: boolean;
  small?: boolean;
  list?: boolean;
  className?: string;
}

export const SimpleStatBlock = ({
  title,
  rows,
  dark = false,
  small = false,
  list = false,
  className = '',
}: Props) => {
  const renderRows = useMemo(
    () =>
      rows.map((data, index) => (
        <div className={classes.DataRow} key={index}>
          <div className={classes.Name}>
            {list ? <span>{index + 1}.</span> : null} {data.name}
          </div>
          <div className={classes.Value}>{data.value}</div>
        </div>
      )),
    [rows],
  );

  return (
    <div
      className={cx(classes.Wrap, className, {
        [classes.Dark]: dark,
        [classes.Small]: small,
        [classes.List]: list,
      })}
    >
      <div className={classes.Title}>{title}</div>
      <div className={classes.Content}>{renderRows}</div>
    </div>
  );
};
