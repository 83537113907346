import { useMemo } from 'react';
import { Flex, Menu, UnstyledButton, keys, rem } from '@mantine/core';
import { DotsThreeVertical } from '@phosphor-icons/react/dist/ssr';
import { RowAction, TableDataRow } from '../interfaces';
import classes from './RowActions.module.css';

interface Props {
  row: TableDataRow;
  allActionsInMenu?: boolean;
  actions: RowAction[];
}

export const RowActions = ({ row, allActionsInMenu, actions }: Props) => {
  const visibleActions = useMemo(() => {
    const visible = actions.filter((action) => {
      if (!action?.when) return action;

      const conditionKeys = keys(action.when);

      const shouldReturn = conditionKeys.some((key) => {
        if (typeof key !== 'string') return false;
        if (!action?.when) return true;

        const value = row[key];
        const valuesToCompare = action?.when ? action.when[key] : [];

        return valuesToCompare.some((item) => {
          if (item?.includes('!')) {
            const valueA = item?.replace('!', '').toLowerCase();
            const valueB = value.value?.toLowerCase();
            return valueA !== valueB;
          }

          return item?.toLowerCase() === value.value?.toLowerCase();
        });
      });

      return shouldReturn;
    });

    return visible || [];
  }, [actions, row]);

  const [firstAction, ...otherActions] = visibleActions;

  if (visibleActions.length === 0) return <></>;

  return (
    <Flex align="center" justify="flex-end">
      {!allActionsInMenu && (
        <UnstyledButton
          onClick={() => firstAction?.callback(row._id.value)}
          fz={rem(12)}
          fw={400}
          c="#8F9098"
        >
          {firstAction?.label}
        </UnstyledButton>
      )}
      {(otherActions.length > 0 || allActionsInMenu) && (
        <Menu position="bottom-end">
          <Menu.Target>
            <UnstyledButton className={classes.dropdown}>
              <DotsThreeVertical size={22} />
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {(allActionsInMenu ? visibleActions : otherActions).map(
              (action) => (
                <Menu.Item
                  color={action.color}
                  key={action.label}
                  onClick={() => action.callback(row._id.value)}
                >
                  {action.label}
                </Menu.Item>
              ),
            )}
          </Menu.Dropdown>
        </Menu>
      )}
    </Flex>
  );
};
