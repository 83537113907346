import { ReactNode } from 'react';
import { Flex, Text, rem } from '@mantine/core';
import cx from 'classnames';
import classes from './Info.module.css';

interface Props {
  className?: string;
  title: string;
  value?: ReactNode;
}

export const Info = ({ className, title, value }: Props) => (
  <Flex direction="column" gap={rem(8)} maw={rem(280)}>
    <Text className={classes.title}>{title}</Text>
    <Text className={cx(classes.value, className)}>{value ?? '-'}</Text>
  </Flex>
);
